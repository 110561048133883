<!--
 * @Author: your name
 * @Date: 2020-10-13 15:20:46
 * @LastEditTime: 2020-10-14 15:46:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /yue_quanzhan_h5/src/views/homePopup/newPeopleList.vue
-->
<template>
  <div>
    <ul class="goodsul">
      <li v-for="(item, j) in list[0].goodsInfo" :key="j" @click="handleClick(item)">
        <img :src="item.goodCover" class="goodsimg">
        <div class="goodsright">
          <div class="goods_name">{{ item.goodName }}</div>
          <div class="priceBoxs">
            <div>
              <span class="xj">￥<span>{{ item.goodVipPrice }}</span></span>
              <span class="yj">￥{{ item.goodPrice }}</span>
            </div>
            <p class="plus" @click="hanledPlus(index+1)">+</p>
          </div>
        </div>
      </li>
    </ul>
    <div class="bottom">
      <div class="icon-box" @click="routerLink('/shopCar')">
        <img class="icon" src="@/assets/images/shop-car.png">
        <p>购物车</p>
        <span v-show="show" class="txt">{{ index }}</span>
      </div>
      <div class="btn" @click="willBuy">确认</div>
    </div>
  </div>
</template>
<script>
import { getActivityList, getActivityCategoryInfo } from '@/services/activity'
import { Toast } from 'vant'

export default {
  data() {
    return {
      activityInfo: {},
      list: [],
      show: false,
      index: ''
    }
  },
  created() {
    this.getActivityList()
    this.getActivityCategoryInfo()
  },
  beforeCreate() {
    // 修改背景色
    document.querySelector('body').setAttribute('style', 'background-color:#F8F8F8')
  },
  beforeDestroy() {
    // 销毁背景色
    document.querySelector('body').removeAttribute('style')
  },
  methods: {
    // 获取信息
    getActivityList() {
      this.id = this.$route.query.id
      const opt = {
        id: 573,
        // categoryId: 0, 不知道是什么
        page: 1,
        pageSize: 10
      }
      getActivityList(opt).then(res => {
        if (Number(res.code) === 200) {
          this.list = res.data
        }
      })
    },
    getActivityCategoryInfo() {
      const params = {
        id: 573
      }
      getActivityCategoryInfo(params).then((res) => {
        if (Number(res.code) === 200) {
          this.activityInfo = res.data.activityInfo
        } else {
          Toast(res.msg)
        }
      })
    },
    handleClick(item) {
      // this.$router.push({
      //     path: '/goodsDetailZY', query:{skuid:item.skuId}
      // });
    },
    routerLink(url) {
      // 路由跳转
      this.$router.push(url)
    },
    willBuy() {

    },
    hanledPlus() {
      this.show = true
      this.index++
      if (this.index > 3) {
        this.index = 3
      }
    }

  }
}
</script>
<style scoped>
.goodsul {
  width: 350px;
  margin: 18px auto
}

.goodsul:after {
  content: '';
  height: 0;
  line-height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}

.goodsul li {
  margin: 0 8px 8px auto;
  width: 170px;
  height: 266px;
  background: #ffffff;
  border-radius: 6px;
  float: left;
  position: relative;
}

.goodsul li:nth-child(2n) {
  margin: 0 0px 8px auto;
}

.goodsimg {
  width: 170px;
  height: 170px;
  background: #cecece;
  border-radius: 5px 5px 0px 0px;
}

.goodsright {
  width: 170px;
  box-sizing: border-box;
  padding: 10px 10px 15px 10px;
}

.goods_name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 14px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #141414;
  height: 36px;
  line-height: 20px;
  margin-bottom: 18px;
}

.goods_name span {
  width: 16px;
  height: 16px;
  line-height: 16px;
  background: #e33f44;
  border-radius: 2px;
  font-size: 12px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #fefefe;
}

.goodsRightImg {
  width: 60px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  background: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-10-13/19/yuelvhuiKGmAyLbvMV1602588085.jpg") no-repeat;
  background-size: 100% 100%;
  margin: 6px 0 14px 0;
}

.goodsRightImg p {
  font-size: 12px;
  color: #E33F44;
}

.prices {
  margin-bottom: 5px
}

.prices, .prices span:first-child {
  font-size: 12px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
}

.prices span:first-child {
  color: #E33F44;
}

.prices span:nth-child(2) {
  font-size: 16px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  color: #e33f44;
}

.yjPrice {
  font-size: 12px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-decoration: line-through;
  text-align: left;
  color: #999999;
}

.shoppBtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shoppBtn button {
  width: 74px;
  height: 28px;
  line-height: 28px;
  background: #e33f44;
  border-radius: 5px;
  font-size: 12px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #fefefe;
}

.botTxt {
  width: 350px;
  margin: 16px auto 46px auto;
}

.priceBoxs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plus {
  width: 20px;
  height: 20px;
  background: #e33f44;
  border-radius: 10px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  font-weight: 600;
  position: static;
  z-index: 99;
}

.xj {
  font-size: 12px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #e43f44;
  margin-right: 4px;
}

.xj span {
  font-size: 18px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #e43f44;
}

.yj {
  font-size: 12px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  text-decoration: line-through;
  text-align: left;
  color: #999999;
}

.bottom {
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px 0;
  z-index: 1000
}

.icon-box {
  position: relative;
}

.icon-box img {
  width: 26px;
}

.icon-box p {
  font-size: 12px;
}

.icon-box .txt {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: #ff001b;
  position: absolute;
  top: -6px;
  right: -12px;
  color: #fff;
  font-size: 12px;
  border-radius: 50%;
}

.btn {
  width: 260px;
  height: 40px;
  line-height: 40px;
  background: #ff001b;
  border-radius: 53px;
  font-size: 18px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}

</style>

