import request from '@/services/request.js'

export function getActivityList(data) {
  return request({
    url: '/mall/V3/newActivityList',
    method: 'post',
    data,
    prefix: 'activity'
  })
}

// 领券专区
export function voucherList(data) {
  return request({
    url: '/mall/V2/getCouponList',
    method: 'post',
    data,
    prefix: 'center'
  })
}

// 领取优惠券
export function postVouchers(data) {
  return request({
    url: '/mall/sendCoupon',
    method: 'post',
    data,
    prefix: 'center'
  })
}

// 获取cps
export function getcpsInfo(data) {
  return request({
    url: '/outside/shareJumpUrl',
    method: 'post',
    data,
    prefix: 'center'
  })
}

// 白拿活动
// 获取白拿类型
export function getActivityCategoryInfo(data) {
  return request({
    url: '/mall/V3/getActivityCategoryInfo',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 获取白拿列表
export function newActivityList(data) {
  return request({
    url: '/mall/V3/newActivityList',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// CPS列表
export function getCpsActiveList(data) {
  return request({
    url: '/mall/activity/getCpsActiveList',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 会场专属大礼包领取
export function lingQuLibao(data) {
  return request({
    url: '/mall/bindCoupon',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 会场专属大礼包判断是否已领取
export function isGetDaLiBao(data) {
  return request({
    url: '/mall/isBindCoupon',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 分享
export function sharePage(data) {
  return request({
    url: data,
    method: 'get',
    prefix: 'yuetaoGroup'
  })
}

// 高德活动--领取大礼包
export function lingQuGaoDeLibao(data) {
  return request({
    url: '/mall/bindActivityCoupon',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 高德活动--查看是否已经领取大礼包
export function isGetGaoDeDaLiBao(data) {
  return request({
    url: '/mall/isActivityBindCoupon',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 高德活动--获取随机codeNumber
export function getCodeNumber(data) {
  return request({
    url: '/mall/shareUser',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 领券中心--2020.09.04
// 获取领劵中心头部
export function getNewCoupon(data) {
  return request({
    // url: 'http://test-gateway.yuetao.vip/shop/v1/mall/coupon/getReceiveCentrumTop',
    url: '/mall/coupon/getReceiveCentrumTop',
    method: 'post',
    data
    // prefix: 'center'
  })
}

// 获取自营优惠卷
export function getYTCoupon(data) {
  return request({
    url: '/mall/V4/getSelfCouponIndex',
    method: 'post',
    data
  })
}

// 获取cps优惠卷
export function getCPSCoupon(data) {
  return request({
    url: '/mall/V4/getCpsCouponIndex',
    method: 'post',
    data
  })
}

// 限时秒杀
export function getNowDateCoupon(data) {
  return request({
    url: '/mall/coupon/getNowDateSeckillList',
    method: 'post',
    data
  })
}

// 领取优惠卷 卷包
export function getCouponBag(data) {
  return request({
    // http://dev.yuetao-mall.com
    url: '/mall/coupon/receiveCoupons',
    method: 'post',
    data
  })
}

// 获取已领取优惠卷包
export function getHasCoupon(data) {
  return request({
    // http://dev.yuetao-mall.com
    url: '/mall/V2/getCouponList',
    method: 'post',
    data
  })
}

// 领取单张优惠卷
export function getSingleCoupon(data) {
  return request({
    // https://gateway.yuetao.vip/shop/v1
    url: '/mall/sendCoupon',
    method: 'post',
    data
  })
}

export function getExchangeShop(data) {
  return request({
    // url:"http://test-gateway.yuetao.vip/shop/v1/mall/card/exchange",
    url: '/mall/card/exchange',
    method: 'post',
    data
  })
}

// 获取兑换券的列表
export function exchangeCertificate(data) {
  return request({
    url: '/app/coupon/exchangeCertificate',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}

// 双十一主会场
export function mainVenueList(data) {
  return request({
    url: '/mall/doubleEleven/mainVenueList',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}

// //分会场接口
export function GetBrandVenueList(data) {
  return request({
    url: '/mall/doubleEleven/brandVenueList',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}

// 分会场接口
// export function GetBrandVenueList(data) {
//     return request({
//         url: '/activity/doubleEleven/brandVenueList',
//         method: 'post',
//         data,
//         prefix: 'yuelvhui'
//     })
// }

// 创富榜
export function GetRichList(data) {
  return request({
    url: '/mall/doubleEleven/richList',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}

// 我的团队
export function MyTeam(data) {
  return request({
    url: '/mall/doubleEleven/myTeam',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}

// 优惠券获取分会场id（双十一活动）
export function useHallCoupon(data) {
  return request({
    url: '/mall/doubleEleven/useHallCoupon',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}

// 获取活动分享信息
export function activityShareInfo(data) {
  return request({
    url: '/mall/activityShareInfo',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}

/**
 * 获取C店商品列表 - 活动
 * 2021-10-15 17:03:10
 * @author SnowRock
 * @returns {Promise<*|undefined>}
 */
export function fetchCShopMallList(params) {
  return request({
    url: '/shop/v1/youxuan/kingkong/getTempCshopProductList',
    data: params,
    method: 'post'
  })
}
